import resume from '../assets/pdf/resume.pdf'
import image from '../assets/myphoto/photo.png'

export const headerData = {
    name: 'Syed Sharukhali',
    title: "Software Developer",
    desc1:"A servant of god with an aim to make this world a better place for all living beings with my work,skills,experience,knowledge",
    desc2: "I also love to explore new technologies and leverage them to solve real-life problems.",
    image: image,
    resumePdf: resume
}
