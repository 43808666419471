import one from '../assets/png/bitcoincerti/1.png'
import two from '../assets/png/bitcoincerti/2.png'


export const blogData = [
    {
        "id": 2,
        "title": "CS120: Bitcoin for Developers I",
        "description": "Saylor Academy",
        "date": "Mar-23",
        "image": two,
        "url": "https://learn.saylor.org/admin/tool/certificate/index.php?code=8802874706SS"
    },
    {
        "id": 1,
        "title": "PRDV151: Bitcoin for Everybody",
        "description": "Saylor Academy",
        "date": "Mar-23",
        "image": one,
        "url": "https://learn.saylor.org/admin/tool/certificate/index.php?code=6962526421SS"
    }
    
]




// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/