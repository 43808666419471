import one from '../assets/png/iitcerti/1.png'
import two from '../assets/png/iitcerti/2.png'
import three from '../assets/png/iitcerti/3.png'
import four from '../assets/png/iitcerti/4.png'


export const blogData = [
    {
        "id": 1,
        "title": "C & CPP Training",
        "description": "Indian Institute of Technology, Bombay",
        "date": "Jul-22",
        "image": one,
        "url": "https://drive.google.com/file/d/1AWWSOzwZ1pgOlUafLWAK3g56ENgtACm6/view?usp=share_link"
    },
    {
        "id": 2,
        "title": "Artificial Intelligence: Search Methods for Problem Solving",
        "description": "Indian Institute of Technology, Madras",
        "date": "Oct-22",
        "image": two,
        "url": "https://nptel.ac.in/noc/E_Certificate/NPTEL22CS67S4358216510104297"
    },
    {
        "id": 3,
        "title": "Essential Mathematics for Machine Learning",
        "description": "Indian Institute of Technology, Roorkee",
        "date": "Oct-22",
        "image": three,
        "url": "https://nptel.ac.in/noc/E_Certificate/NPTEL22MA40S6358199910104297"
    },
    {
        "id": 4,
        "title": "Data Analytics with python",
        "description": "Indian Institute of Technology, Roorkee",
        "date": "April-23",
        "image": four,
        "url": "https://nptel.ac.in/noc/E_Certificate/NPTEL23CS08S6422234104265791"
    }
    
    
]




// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/