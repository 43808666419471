/* eslint-disable */
import { BiShoppingBag, BiPencil } from "react-icons/bi";
import { BsCodeSlash, BsClipboardData } from "react-icons/bs";
import { AiOutlineMail, AiFillAudio } from "react-icons/ai";
/* eslint-disable */
import { BiBrain } from "react-icons/bi"; // Icon for Machine Learning
import { FaEthereum } from "react-icons/fa"; // Icon for Web3
import { AiOutlineDatabase } from "react-icons/ai"; // Icon for Data Science
import { GiTrophy } from "react-icons/gi"; // Icon for Competitive Programming
import { SiWebmoney } from "react-icons/si"; // Icon for Basic Web Development
import { FaLaptopCode } from "react-icons/fa"; // Icon for Software Engineering

export const servicesData = [
    {
        id: 1,
        title: 'Machine Learning',
        icon: <BiBrain />
    },
    {
        id: 2,
        title: 'Web3',
        icon: <FaEthereum />
    },
    {
        id: 3,
        title: 'Data Science',
        icon: <AiOutlineDatabase />
    },
    {
        id: 4,
        title: 'Data Structures and Algorithms',
        icon: <BsCodeSlash />
    },
    {
        id: 5,
        title: 'Competitive Programming',
        icon: <GiTrophy />
    },
    {
        id: 6,
        title: 'Basic Web Development',
        icon: <SiWebmoney />
    },
    {
        id: 7,
        title: 'Software Engineering',
        icon: <FaLaptopCode />
    }
];