import one from '../assets/png/achievements/one.png'
import two from '../assets/png/achievements/two.jpeg'
import three from '../assets/png/achievements/three.png'
import four from '../assets/png/achievements/four.png'
import five from '../assets/png/achievements/five.png'
import six from '../assets/png/achievements/six.png'
import seven from '../assets/png/achievements/seven.png'
import eight from '../assets/png/achievements/eight.png'

export const blogData = [
    {
        id: 1,
        title: '2nd Rank: SSC Board Examination, Maharashtra, INDIA',
        description: 'I ranked 2nd by scoring 93% in State Board Exam, Stood 1st in Science & Technology, Mathematics and Social Sciences',
        date: 'March, 2018',
        image: one,
        url: 'https://drive.google.com/drive/folders/17rU7nM_i0F4MQHgdGS4BYnNoNBwOgSfY?usp=sharing'
    },
    {
        id: 2,
        title: 'Solve For INDIA Hackathon Institue winner and regional Qualifier',
        description: 'Won the institute round of Solve For INDIA Hackathon(#GOOGLE #AMD) and was selected for the regional round',
        date: 'April, 2023',
        image: two,
        url: 'https://drive.google.com/drive/folders/1Oxq4gVxd6vUFdQEnx8Czwx9TOcbI0BqM?usp=sharing'
    },
    {
        id: 3,
        title: 'CSI HACKATHON Runner up',
        description: 'Here are some basic ways to lay off the Radar',
        date: 'Aug 14, 2020',
        image: three,
        url: 'https://verification.givemycertificate.com/v/537cb406-6cd3-4b61-8dc2-1a6e2a575963'
    },
    {
        id: 4,
        title: 'AIR 135: Coding Ninjas CODE COMBAT 3.0',
        description: 'Want to check your internet speed from the terminal? Here\'s the perfect way to the that.',
        date: 'Sep 17, 2020',
        image: four,
        url: 'https://ampsp.webengage.co/f/a/8mLsSf99fEaKhjrUKGvW2w~~/AAQNhwA~/RgRmqgXiP4T4AWh0dHBzOi8vYy53ZWJlbmdhZ2UuY29tL2x3L2cxLmpwZz9wPWV5SnNZeUk2SW40ME4ySTJOakEyTkNJc0ltd2lPaUkzTXpneE1XWXlObVJpWTJFeU5UTXhNbVE0WW1Oa05ETTFZakkxTWpNMVpETmtNemhtWVRreklpd2lZeUk2SWpJMk5EZ3dNVEFpTENKbGJTSTZJbk5vWVdoellXa3hNVEV4TVVCbmJXRnBiQzVqYjIwaUxDSmxJam9pZmpKaGMyZzViM0FpTENKMklqb2lmakZwYnpGamEyY2lMQ0p6SWpvaU16ZzJZV1l6T1dVdE9EUmtOUzAwTmpSaUxUZ3daamt0TWpJME5UZzVNVEZsWW1RMUlpd2lhaUk2SW40Mk9HVTBaMkpqSWl3aVpYWmxiblFpT2lKbGJXRnBiRjlqYkdsamF5SXNJbU4wWVNJNkluNDNaRFV5WVdKbE55SXNJblJ2VlZKTUlqb2lhSFIwY0hNNkx5OXVhVzVxWVhObWFXeGxjeTV6TXk1aGJXRjZiMjVoZDNNdVkyOXRMMk5sY25ScFptbGpZWFJsWDI5bVgyVjJaVzUwWDJOdlpHVmZZMjl0WW1GMFh6TmZNRjg0TXpFd1lXUXhaalkwTlRVMU5XVmpObUV4WWk1d1pHWWlmUSUzRCUzRFcDc3BjQgpkveKAx2QqP3-7UhZzaGFoc2FpMTExMTFAZ21haWwuY29tWAQAAAJB'
    },
    {
        id: 5,
        title: 'Runner up @ inter department debate competition',
        description: 'Runner up at the inter department debate competition on the topic "Is AI a threat to humanity ?" and "is CGPA necessary aspect ?".',
        date: 'Oct 1, 2020',
        image: five,
        url: ''
    },
    {
        id: 6,
        title: 'Rank 1 @ HackerRank SSC-3',
        description: 'Solved 3 Medium Level Leedcode Questions within 15 minutes',
        date: 'Oct 1, 2020',
        image:six,
        url: 'https://www.hackerrank.com/contests/shastra-challenge-3-contest-a/leaderboard'
    },
    {
        id: 7,
        title: 'Won the Nexathon 2024 Hackathon',
        description: '',
        date: 'Oct 1, 2020',
        image: seven, 
        url: ''
    },
    {
        id: 8,
        title: 'Qalified for the final round of UnFold23 Hackathon by CoinDCX',
        description: 'Qualified for the final round of UnFold23 Hackathon by CoinDCX at Bangalore with a sponsored trip and accomodation',
        date: 'Oct 1, 2020',
        image: eight,
        url: ''

    }
]




// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/