import one from '../assets/png/cloudcerti/1.png'
import two from '../assets/png/cloudcerti/2.png'
import three from '../assets/png/cloudcerti/3.png'
import four from '../assets/png/cloudcerti/4.png'
import five from '../assets/png/cloudcerti/5.png'
import six from '../assets/png/cloudcerti/6.png'
import seven from '../assets/png/cloudcerti/7.png'
import eight from '../assets/png/cloudcerti/8.png'
import nine from '../assets/png/cloudcerti/9.png'
import ten from '../assets/png/cloudcerti/10.png'
import eleven from '../assets/png/cloudcerti/11.png'
import twelve from '../assets/png/cloudcerti/12.png'
import thirteen from '../assets/png/cloudcerti/13.png'
import fourteen from '../assets/png/cloudcerti/14.png'
import fifteen from '../assets/png/cloudcerti/15.png'
import sixteen from '../assets/png/cloudcerti/16.png'
import seventeen from '../assets/png/cloudcerti/17.png'
import eighteen from '../assets/png/cloudcerti/18.png'
import nineteen from '../assets/png/cloudcerti/19.png'
import twenty from '../assets/png/cloudcerti/20.png'
import twentyone from '../assets/png/cloudcerti/21.png'
import twentytwo from '../assets/png/cloudcerti/22.png'
import twentythree from '../assets/png/cloudcerti/23.png'
import twentyfour from '../assets/png/cloudcerti/24.png'
import twentyfive from '../assets/png/cloudcerti/25.png'
import twentysix from '../assets/png/cloudcerti/26.png'
import twentyseven from '../assets/png/cloudcerti/27.png'
import twentyeight from '../assets/png/cloudcerti/28.png'
import twentynine from '../assets/png/cloudcerti/29.png'
import thirty from '../assets/png/cloudcerti/30.png'



export const blogData = [
        {
            "id": 1,
            "title": "Use Functions, Formulas, and Charts in Google Sheets",
            "description": "Google Cloud Skills Boost",
            "date": "Apr-23",
            "image": one,
            "url": "https://www.cloudskillsboost.google/public_profiles/7322d512-7cb2-452e-9746-9432ea161f52/badges/3492307?utm_medium=social&utm_source=linkedin&utm_campaign=ql-social-share",
            
        },
        {
            "id": 2,
            "title": "Level 2: Data Exploration with Looker, BigQuery and Sheets",
            "description": "Google Cloud Skills Boost",
            "date": "Apr-23",
            "image": two,
            "url": "https://www.cloudskillsboost.google/public_profiles/7322d512-7cb2-452e-9746-9432ea161f52/badges/3491394?utm_medium=social&utm_source=linkedin&utm_campaign=ql-social-share",
            
        },
        {
            "id": 3,
            "title": "Analyze BigQuery Data in Connected Sheets",
            "description": "Google Cloud Skills Boost",
            "date": "Apr-23",
            "image": three,
            "url": "https://www.cloudskillsboost.google/public_profiles/7322d512-7cb2-452e-9746-9432ea161f52/badges/3490604?utm_medium=social&utm_source=linkedin&utm_campaign=ql-social-share",
            
        },
        {
            "id": 4,
            "title": "Exploring Data with Looker",
            "description": "Google Cloud Skills Boost",
            "date": "Apr-23",
            "image": four,
            "url": "https://www.cloudskillsboost.google/public_profiles/7322d512-7cb2-452e-9746-9432ea161f52/badges/3486891?utm_medium=social&utm_source=linkedin&utm_campaign=ql-social-share",
            
        },
        {
            "id": 5,
            "title": "Store, Process, and Manage Data on Google Cloud - Command Line",
            "description": "Google Cloud Skills Boost",
            "date": "Apr-23",
            "image": five,
            "url": "https://www.cloudskillsboost.google/public_profiles/7322d512-7cb2-452e-9746-9432ea161f52/badges/3480988?utm_medium=social&utm_source=linkedin&utm_campaign=ql-social-share",
            
        },
        {
            "id": 6,
            "title": "Store, Process, and Manage Data on Google Cloud - Console",
            "description": "Google Cloud Skills Boost",
            "date": "Apr-23",
            "image": six,
            "url": "https://www.cloudskillsboost.google/public_profiles/7322d512-7cb2-452e-9746-9432ea161f52/badges/3480964?utm_medium=social&utm_source=linkedin&utm_campaign=ql-social-share",
            
        },
        {
            "id": 7,
            "title": "Level 1: Managing Resources and Data in the Cloud",
            "description": "Google Cloud Skills Boost",
            "date": "Apr-23",
            "image": seven,
            "url": "https://www.cloudskillsboost.google/public_profiles/7322d512-7cb2-452e-9746-9432ea161f52/badges/3480960?utm_medium=social&utm_source=linkedin&utm_campaign=ql-social-share",
            
        },
        {
            "id": 8,
            "title": "Monitor and Manage Google Cloud Resources",
            "description": "Google Cloud Skills Boost",
            "date": "Apr-23",   
            "image": eight,
            "url": "https://www.cloudskillsboost.google/public_profiles/7322d512-7cb2-452e-9746-9432ea161f52/badges/3478937?utm_medium=social&utm_source=linkedin&utm_campaign=ql-social-share",
            
        },
        {
            "id": 9,
            "title": "Data Without the Degree [Arcade]",
            "description": "Google Cloud Skills Boost",
            "date": "Mar-23",
            "image": nine,
            "url": "https://www.cloudskillsboost.google/public_profiles/7322d512-7cb2-452e-9746-9432ea161f52/badges/3326339?utm_medium=social&utm_source=linkedin&utm_campaign=ql-social-share",
            
        },
        {
            "id": 10,
            "title": "Engineer Data in Google Cloud",
            "description": "Google Cloud Skills Boost",
            "date": "Apr-23",
            "image": ten,
            "url": "https://www.cloudskillsboost.google/public_profiles/7322d512-7cb2-452e-9746-9432ea161f52/badges/3539954?utm_medium=social&utm_source=linkedin&utm_campaign=ql-social-share",
           
        },
        {
            "id": 11,
            "title": "App Building with AppSheet",
            "description": "Google Cloud Skills Boost",
            "date": "May-23",
            "image": eleven,
            "url": "https://www.cloudskillsboost.google/public_profiles/7322d512-7cb2-452e-9746-9432ea161f52/badges/3747159?utm_medium=social&utm_source=linkedin&utm_campaign=ql-social-share",
          
        },
        {
            "id": 12,
            "title": "Cloud Functions: 3 Ways",
            "description": "Google Cloud Skills Boost",
            "date": "May-23",
            "image": twelve,
            "url": "https://www.cloudskillsboost.google/public_profiles/7322d512-7cb2-452e-9746-9432ea161f52/badges/3747481?utm_medium=social&utm_source=linkedin&utm_campaign=ql-social-share",
            
        },
        {
            "id": 13,
            "title": "Level 2: Citizen Developer Skills",
            "description": "Google Cloud Skills Boost",
            "date": "May-23",
            "image": thirteen,
            "url": "https://www.cloudskillsboost.google/public_profiles/7322d512-7cb2-452e-9746-9432ea161f52/badges/3788906?utm_medium=social&utm_source=linkedin&utm_campaign=ql-social-share",
            
        },
        {
            "id": 14,
            "title": "Integrate with Machine Learning APIs",
            "description": "Google Cloud Skills Boost",
            "date": "May-23",
            "image": fourteen,
            "url": "https://www.cloudskillsboost.google/public_profiles/7322d512-7cb2-452e-9746-9432ea161f52/badges/3727684?utm_medium=social&utm_source=linkedin&utm_campaign=ql-social-share",
           
        },
        {
            "id": 15,
            "title": "Level 1: Data, ML and API Skills",
            "description": "Google Cloud Skills Boost",
            "date": "May-23",
            "image": fifteen,
            "url": "https://www.cloudskillsboost.google/public_profiles/7322d512-7cb2-452e-9746-9432ea161f52/badges/3727191?utm_medium=social&utm_source=linkedin&utm_campaign=ql-social-share",
          
        },
        {
            "id": 16,
            "title": "Get Started with API Gateway",
            "description": "Google Cloud Skills Boost",
            "date": "May-23",
            "image": sixteen,
            "url": "https://www.cloudskillsboost.google/public_profiles/7322d512-7cb2-452e-9746-9432ea161f52/badges/3715063?utm_medium=social&utm_source=linkedin&utm_campaign=ql-social-share",
            
        },
        {
            "id": 17,
            "title": "Build and Optimize Data Warehouses with BigQuery",
            "description": "Google Cloud Skills Boost",
            "date": "May-23",
            "image": seventeen,
            "url": "https://www.cloudskillsboost.google/public_profiles/7322d512-7cb2-452e-9746-9432ea161f52/badges/3708802?utm_medium=social&utm_source=linkedin&utm_campaign=ql-social-share",
            
        },
        {
            "id": 18,
            "title": "Streaming Analytics into BigQuery",
            "description": "Google Cloud Skills Boost",
            "date": "May-23",
            "image": eighteen,
            "url": "https://www.cloudskillsboost.google/public_profiles/7322d512-7cb2-452e-9746-9432ea161f52/badges/3708516?utm_medium=social&utm_source=linkedin&utm_campaign=ql-social-share",
           
        },
        {
            "id": 19,
            "title": "Qwiklabs Trivia April 2023",
            "description": "Google Cloud Skills Boost",
            "date": "Apr-23",
            "image": nineteen,
            "url": "https://www.cloudskillsboost.google/public_profiles/7322d512-7cb2-452e-9746-9432ea161f52/badges/3583929?utm_medium=social&utm_source=linkedin&utm_campaign=ql-social-share",
            
        },
        {
            "id": 20,
            "title": "Perform Foundational Infrastructure Tasks in Google Cloud",
            "description": "Google Cloud Skills Boost",
            "date": "Apr-23",
            "image": twenty,
            "url": "https://www.cloudskillsboost.google/public_profiles/7322d512-7cb2-452e-9746-9432ea161f52/badges/3537509?utm_medium=social&utm_source=linkedin&utm_campaign=ql-social-share",
            
        },
        {
            "id": 21,
            "title": "Automating Infrastructure on Google Cloud with Terraform",
            "description": "Google Cloud Skills Boost",
            "date": "Apr-23",
            "image": twentyone,
            "url": "https://www.cloudskillsboost.google/public_profiles/7322d512-7cb2-452e-9746-9432ea161f52/badges/3538201?utm_medium=social&utm_source=linkedin&utm_campaign=ql-social-share",
          
        },
        {
            "id": 22,
            "title": "Prepare Data for ML APIs on Google Cloud",
            "description": "Google Cloud Skills Boost",
            "date": "4/10/2023",
            "image": twentytwo,
            "url": "https://www.cloudskillsboost.google/public_profiles/bc7f4401-656f-4032-ab0f-d22ed078a529/badges/5428035",
          
        },
        {
            "id": 23,
            "title": "Build a Secure Google Cloud Network",
            "description": "Google Cloud Skills Boost",
            "date": "4/10/2023",
            "image": twentythree,
            "url": "https://www.cloudskillsboost.google/public_profiles/bc7f4401-656f-4032-ab0f-d22ed078a529/badges/5427802",
            
        },
        {
            "id": 24,
            "title": "Set Up an App Dev Environment on Google Cloud",
            "description": "Google Cloud Skills Boost",
            "date": "4/10/2023",
            "image": twentyfour,
            "url": "https://www.cloudskillsboost.google/public_profiles/bc7f4401-656f-4032-ab0f-d22ed078a529/badges/5427243",
            
        },
        {
            "id": 25,
            "title": "Implement Load Balancing on Compute Engine",
            "description": "Google Cloud Skills Boost",
            "date": "4/10/2023",
            "image": twentyfive,
            "url": "https://www.cloudskillsboost.google/public_profiles/bc7f4401-656f-4032-ab0f-d22ed078a529/badges/5427032",
           
        },
        {
            "id": 26,
            "title": "Google Cloud Computing Foundations: Data, ML, and AI in Google Cloud",
            "description": "Google Cloud Skills Boost",
            "date": "4/10/2023",
            "image": twentysix,
            "url": "https://www.cloudskillsboost.google/public_profiles/bc7f4401-656f-4032-ab0f-d22ed078a529/badges/5426565",
            
        },
        {
            "id": 27,
            "title": "Google Cloud Computing Foundations: Networking & Security in Google Cloud",
            "description": "Google Cloud Skills Boost",
            "date": "4/10/2023",
            "image": twentyseven,
            "url": "https://www.cloudskillsboost.google/public_profiles/bc7f4401-656f-4032-ab0f-d22ed078a529/badges/5424531",
            
        },
        {
            "id": 28,
            "title": "Google Cloud Computing Foundations: Infrastructure in Google Cloud",
            "description": "Google Cloud Skills Boost",
            "date": "4/10/2023",
            "image": twentyeight,
            "url": "https://www.cloudskillsboost.google/public_profiles/bc7f4401-656f-4032-ab0f-d22ed078a529/badges/5423027",
            
        },
        {
            "id": 29,
            "title": "Google Cloud Computing Foundations: Cloud Computing Fundamentals",
            "description": "Google Cloud Skills Boost",
            "date": "4/10/2023",
            "image": twentynine,
            "url": "https://www.cloudskillsboost.google/public_profiles/bc7f4401-656f-4032-ab0f-d22ed078a529/badges/5421878",
            
        },
        {
            "id": 30,
            "title": "Level 3: GenAI",
            "description": "Google Cloud Skills Boost",
            "date": "5/9/2023",
            "image": thirty,
            "url": "https://www.cloudskillsboost.google/public_profiles/bc7f4401-656f-4032-ab0f-d22ed078a529/badges/5411675",
        
        }
]




// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/