import one from '../assets/png/projects/one.png'
import two from '../assets/png/projects/two.png'
import three from '../assets/png/projects/three.png'
import four from '../assets/png/projects/four.png'
import five from '../assets/png/projects/five.png'
import six from '../assets/png/projects/six.jpg'
import seven from '../assets/png/projects/seven.png'
import eight from '../assets/png/projects/eight.png'
import nine from '../assets/png/projects/nine.png'
import ten from '../assets/png/projects/ten.png'
import { NotInterested } from '@material-ui/icons'


export const projectsData = [
    {
        id: 1,
        projectName: 'Gamma Sentiment Analyser',
        projectDesc: 'To analyze the sentiment of the text and represent it graphically using charts',
        tags: ['Streamlit','Hugging Face','Python'],
        code: 'https://github.com/AI-ApeX-DeV/SENTIMENT-ANALYSIS-Streamlit',
        demo: 'https://sharukhalisyed.streamlit.app/',
        image: one
    },
    {
        id: 2,
        projectName: 'Lawyantra',
        projectDesc: 'Developed a AI and ML powered web app to help users find the best lawyer for their case based on user prompt and lawyer reviews. Chatbot for legal advice, Forum for legal discussions. Dashboard for lawyers to manage their cases and clients.',
        tags: ['Python', 'Streamlit ,Openai API','Hugging Face API, Bert Model,Transformer, Tokenization and Cosine similarity'],
        code: 'https://github.com/AI-ApeX-DeV/DataHack_2_DTG',
        demo: 'https://shahsai11.streamlit.app/',
        image: two
    },
    {
        id: 3,
        projectName: 'FlavorFuse',
        projectDesc: 'food item recommendation and prediction for hotels,restaurants and canteens, help them see the orders and recommend them food items to be cooked in free hours, predict the food items that can be sold on a particular day so that the management can buy the required stuff accordingly',
        tags: ['Python', 'Tkinter', 'Statistics, Advanced Mathematics, Probability'],
        code: 'https://github.com/AI-ApeX-DeV/FRPM',
        demo: 'https://github.com/AI-ApeX-DeV/FRPM',
        image: three
    },
    {
        id: 4,
        projectName: 'Walmart-Prompt Product Finder',
        projectDesc: 'Finds the appropriate product based on the user prompt from product description,reviews,extracts key attributes from user reviews and customer review sentiment analysis',
        tags: ['NLTK', 'Firebase','Python'],
        code: 'https://github.com/AI-ApeX-DeV/walmart-sparkathon',
        demo: 'https://github.com/AI-ApeX-DeV/walmart-sparkathon',
        image: four
    },
    {
        id: 5,
        projectName: 'Prompt Course recommender',
        projectDesc: 'Extracts best courses from youtube using youtube video sentiment analysis to find the most liked video from user prompt',
        tags: ['Youtube API,Python,Hugging Face API,NLTK,Tokenization','Cosine Similarity'],
        code: 'https://github.com/AI-ApeX-DeV/YOUTUBE-SENTIMENT-ANALYSIS',
        demo: 'https://github.com/AI-ApeX-DeV/YOUTUBE-SENTIMENT-ANALYSIS',
        image: five
    },
    {
        id: 6,
        projectName: 'Asmap Feature Bitcoin Core',
        projectDesc: 'a configuration option that diversifies node connections across different Autonomous Systems to prevent network attacks',
        tags: ['Linux, Git,Python', 'Bitcoin core'],
        code: 'https://github.com/brunoerg/asmapy',
        demo: 'https://github.com/brunoerg/asmapy',
        image: six
    },
    {
        id: 7,
        projectName: 'Code Titan',
        projectDesc: 'To get the user heart rate,body temperature,blood pressure and oxygen level through users face using opencv',
        tags: ['Python,Opencv,Numpy,Pandas'],
        code: 'hhttps://github.com/AI-ApeX-DeV/code_titans',
        demo: 'https://github.com/AI-ApeX-DeV/code_titans',
        image: seven
    },
    {
        id: 8,
        projectName: 'HealthPro',
        projectDesc: 'Disease predictor from symptoms and body part images',
        tags: ['Python, Flask, Opencv,Pytorch,Tensorflow','Pickle,Pyrebase,Firebase','Keras, Openai API'],
        code: 'https://github.com/AI-ApeX-DeV/HEALTHPRO',
        demo: 'https://github.com/AI-ApeX-DeV/HEALTHPRO',
        image: eight
    },
    {
        id: 9,
        projectName: 'Blogging Website for TCET',
        projectDesc: 'Built various APIs for the blogging website of TCET',
        tags: ['Flask,HTML,CSS,JS','Python','Firebase'],
        code: 'https://github.com/AI-ApeX-DeV/TCET_BLOGS',
        demo: 'https://github.com/AI-ApeX-DeV/TCET_BLOGS',
        image: nine
    },
    {
        id: 10,
        projectName: 'Resume Screener',
        projectDesc: 'To extract features from ATS resume and then recommeding the best career path for the user',
        tags: ['OpenCV','NLTK'],
        code: 'https://github.com/AI-ApeX-DeV/LINKEDIN-ANALYSIS',
        demo: 'https://github.com/AI-ApeX-DeV/LINKEDIN-ANALYSIS',
        image: ten
    },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/