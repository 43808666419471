import React from 'react'
import { Helmet } from 'react-helmet'

import { Navbar, Footer, Landing, About, Events, Skills, Talks, Testimonials, Blog, Education, Volunteer, Experience, Contacts, Projects, Services, Achievement, Achievements, Research, Hackathon,Startups, Profcerti, Iitcerti, Courseracerti, Cloudcerti, Bitcoincerti} from '../../components'
import { headerData } from '../../data/headerData'


function Main() {
    return (
        <div>
            <Helmet>
                <title>{headerData.name} - Porfolio</title>
            </Helmet>
            <Navbar />        
            <Landing />
            <Services />
            <Education />
            <Skills />
            <Experience />
            <Volunteer />
            <Startups />
            <Events />
            <Projects />
            <Achievements />
            <Research />
            <Hackathon />
            <Profcerti />
            <Iitcerti />
            <Courseracerti />
            <Cloudcerti />
            <Bitcoincerti />
            <Talks />
            <Testimonials />
            <Contacts />
            <Footer />
        </div>
    )
}

export default Main
