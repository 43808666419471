import one from '../assets/png/hackathons/one.png'
import two from '../assets/png/hackathons/two.png'
import three from '../assets/png/hackathons/three.png'
import four from '../assets/png/hackathons/four.png'
import five from '../assets/png/hackathons/five.png'
import six from '../assets/png/hackathons/six.png'
import seven from '../assets/png/hackathons/seven.jpg'
import eight from '../assets/png/hackathons/eight.jpg'
import nine from '../assets/png/hackathons/nine.jpg'
import ten from '../assets/png/hackathons/ten.jpg'
import eleven from '../assets/png/hackathons/eleven.png'
import twelve from '../assets/png/hackathons/twelve.png'
import thirteen from '../assets/png/hackathons/thirteen.png'
import fourteen from '../assets/png/hackathons/fourteen.jpg'
import fifteen from '../assets/png/hackathons/fifteen.png'
import sixteen from '../assets/png/hackathons/sixteen.jpg'



export const blogData = [
    {
        id: 1,
        title: 'Solve  for INDIA powered by GOOGLE CLOUD AND AMD',
        description: 'Under top 300 teams/ 1 lakh+ participants',
        date: 'March, 2018',
        image: one,
        url: ''
    },
    {
        id: 2,
        title: 'Mumbai Hacks',
        description: 'Qualified for round 2',
        date: 'April, 2023',
        image: two,
        url: ''
    },
    {
        id: 3,
        title: 'CSI TCET',
        description: 'Runner Up',
        date: 'Aug 14, 2020',
        image: three,
        url: ''
    },
    {
        id: 4,
        title: 'Nexathon 2024',
        description: 'Won 6k and 4th position creating a AI, ML, Web3 & AR/VR Powered Estate.AI Platform',
        date: 'Sep 17, 2020',
        image: four,
        url: ''
    },
    {
        id: 5,
        title: 'UNFOLD23 Hackathon by CoinDCX',
        description: 'Selected and Attended the Judging round in Bangalore',
        date: 'Oct 1, 2020',
        image: five,
        url: ''
    },
    {
        id: 6,
        title: 'Smart INDIA Hackathon',
        description: 'Won the Institue round',
        date: 'Oct 1, 2020',
        image:six,
        url: ''
    },
    {
        id: 7,
        title: 'Flipkart GRID ',
        description: 'Participated',
        date: 'Oct 1, 2020',
        image: seven, 
        url: ''
    },
    {
        id: 8,
        title: 'Walmart Sparkathon',
        description: 'Participated',
        date: 'Oct 1, 2020',
        image: eight,
        url: ''

    },
    {
        id: 9,
        title: 'Vivechan case study challenge',
        description: 'Participated',
        date: 'Oct 1, 2020',
        image: nine,
        url: ''
    },
    {
        id: 10,
        title: 'Tata Crucible Quiz 2023',
        description: 'Participated',
        date: 'Oct 1, 2020',
        image: ten,
        url: ''
    },
    {
        id:11,
        title: 'IIT Kharagpur Hackathon',
        description: 'Qualified round 1',
        date: 'Oct 1, 2020',
        image: eleven,
        url: ''
    },
    {
        id:12,
        title: 'DJSC Datathon',
        description: 'Qualified for finals',
        date: 'Oct 1, 2020',
        image: twelve,
        url: ''
    },
    {
        id:13,
        title: 'Quantiphi CodeQraft',
        description: 'Qualified for finals',
        date: 'Oct 1, 2020',
        image: thirteen,
        url: ''
    },
    {
        id:14,
        title: 'American express campus super bowl challenge IIT BOMBAY',
        description: 'Had a chance to participate with my IIT BOMBAY frinds',
        date: 'Oct 1, 2020',
        image: fourteen,
        url: ''
    },
    {
        id:15,
        title: 'GOOGLE SOLUTION CHALLENGE',
        description: 'Had a wonderful Branstorming sessions with mentors',
        date: 'Oct 1, 2020',
        image: fifteen,
        url: ''
    },
    {
        id:16,
        title: 'Xaomi Ode-2-Code 3.0',
        description: 'participated',
        date: 'Oct 1, 2020',
        image: sixteen,
        url: ''
    }
]




// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/