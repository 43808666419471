export const experienceData = [
    {
        id: 1,
        company: 'Mind Benders Club',
        jobtitle: 'President',
        location: 'Mumbai, India',
        startYear: 'July 2023',
        description: '🌟 Leading data science enthusiast | President @ Mind Benders Club, TCET | Passionate about AI, ML, and community building.🚀 Empowering learners | Creating a space for hands-on projects, workshops, and hackathons.🤝 Join our journey | Let’s decode data together! Connect with me and explore the world of data science.',
        endYear: 'present'
    },
    {
        id: 2,
        company: 'Byte Magazine',
        jobtitle: 'Editorial Board Core',
        location: 'Mumbai, India',
        startYear: 'May 2022',
        description: '🌟 Editorial Board Core Member | Byte Magazine 🌟🚀 Contributing to Content Excellence:Finalizing engaging topics that resonate with our audience.Ensuring impeccable vocabulary and clarity in articles.Guiding the working committee to maintain quality standards.🤝 Join Our Journey: Let’s shape impactful narratives and elevate Byte Magazine’s influence together! 📰🔍',
        endYear: 'May 2023'
    },
    {
        id: 3,
        company: 'Pragyaan Magazine',
        jobtitle: 'Editorial Board Core',
        location: 'Mumbai, India',
        startYear: 'March 2023',
        description: '🌟 Editorial Board Core Member | Byte Magazine 🌟🚀 Contributing to Content Excellence:Finalizing engaging topics that resonate with our audience.Ensuring impeccable vocabulary and clarity in articles.Guiding the working committee to maintain quality standards.🤝 Join Our Journey: Let’s shape impactful narratives and elevate Byte Magazine’s influence together! 📰🔍',
        endYear: 'June 2023'
    },
]