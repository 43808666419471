import one from '../assets/png/profcerti/1.png'
import two from '../assets/png/profcerti/2.png'
import three from '../assets/png/profcerti/3.png'
import four from '../assets/png/profcerti/4.png'
import five from '../assets/png/profcerti/5.png'


export const blogData = [
    {
        "id": 1,
        "title": "TensorFlow: Advanced Techniques Specialization",
        "description": "DeepLearning.AI",
        "date": "Apr-23",
        "image": one,
        "url": "https://www.coursera.org/account/accomplishments/specialization/certificate/5UTEBPVZQJKQ"
    },
    {
        "id": 2,
        "title": "Reinforcement Learning Specialization",
        "description": "University of Alberta",
        "date": "Apr-23",
        "image": two,
        "url": "https://www.coursera.org/account/accomplishments/specialization/certificate/959DFU6B2FTT"
    },
    {
        "id": 3,
        "title": "Google Data Analytics Specialization",
        "description": "Google",
        "date": "Apr-23",
        "image": three,
        "url": "https://www.coursera.org/account/accomplishments/specialization/certificate/LDAW3V7P825M"
    },
    {
        "id": 4,
        "title": "Google Project Management: Specialization",
        "description": "Google",
        "date": "Sep-23",
        "image": four,
        "url": "https://www.coursera.org/account/accomplishments/specialization/certificate/7SXK2KUFQLLC"
    },
    {
        "id": 5,
        "title": "Data Scientist Professional",
        "description": "DataCamp",
        "date": "Oct-23",
        "image": five,
        "url": "https://www.datacamp.com/certificate/DS0027950377222"
    }
    
    
]




// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/