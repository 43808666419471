export const socialsData = {
    github: 'https://github.com/ai-ApeX-DeV/',
    facebook: 'https://www.facebook.com/',
    linkedIn: 'https://www.linkedin.com/in/sharukhali-farookali-naziyabegum-syed-2817bb272/',
    instagram: 'https://www.instagram.com/sharukhalisyed/',
    codepen: 'https://codepen.io/',
    twitter: 'https://twitter.com/shahsai11111',
    reddit: 'https://www.reddit.com/user/',
    blogger: 'https://mind-benders.github.io/blog/',
    medium: 'https://medium.com/@',
    stackOverflow: 'https://stackoverflow.com/users/',
    gitlab: 'https://gitlab.com/',
    youtube: 'https://www.youtube.com/@shahsai',
    leetcode: 'https://leetcode.com/SHAHSAI/',
    discord: 'https://discord.com/shahsai',
    whatsapp: 'https://wa.me/919892363057',
}