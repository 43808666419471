export const educationData = [
    {
        id: 1,
        institution: 'St John the Evagelist High School',
        course: 'Secondary Education',
        startYear: '2006',
        endYear: '2018',
        marks: '94%',
    },
    {
        id: 2,
        institution: 'Bhavans College',
        course: 'Higher Secondary Education',
        startYear: '2018',
        endYear: '2020',
        marks: 'MHTCET: 95 percentile'
    },
    {
        id: 3,
        institution: 'Vidyalankar Institute',
        course: 'JEE Advanced Preparation',
        startYear: '2018',
        endYear: '2020',
        marks: 'JEE Mains: 92 percentile'
    },
    {
        id: 4,
        institution: 'Thakur College of Engineering and Technology',
        course: 'B.Tech @CSE',
        startYear: '2021',
        endYear: 'present',
        marks: 'CGPA: 9.94/10 (Till Sem 5)'
    }
]