import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fade from 'react-reveal/Fade';

import { ThemeContext } from '../../contexts/ThemeContext';

import expImgWhite from '../../assets/svg/experience/expImgWhite.svg'
import expImgBlack from '../../assets/svg/experience/expImgBlack.svg'

import './Experience.css'

function ExperienceCard({id, company, jobtitle, startYear, endYear, description}) {

    const { theme } = useContext(ThemeContext);
    const [isHovered, setIsHovered] = useState(false);

    const useStyles = makeStyles((t) => ({
        experienceCard : {
            position: 'relative', // Add this line
            backgroundColor:theme.primary30,
            "&:hover": {
                backgroundColor:theme.primary50,
            },
        },
        tooltip: {
            visibility: isHovered ? 'visible' : 'hidden',
            backgroundColor: theme.secondary,
            color: theme.tertiary,
            border: `1px solid ${theme.tertiary}`,
            borderRadius: '15px',
            padding: '10px',
            margin: '10px',
            position: 'absolute',
            zIndex: '9999',
            top: '50%',
            left: '0',
            transform: 'translate(-100%, -50%)',
            textAlign: 'center',
            opacity: isHovered ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
            width: '70%',
            height: '95%',
            overflow: 'auto',
            boxSizing: 'border-box', // Add this line
            whiteSpace: 'normal', // Add this line
        },
    }));

    const classes = useStyles();

    return (
        <Fade bottom>
            <div 
                key={id} 
                className={`experience-card ${classes.experienceCard}`} 
                onMouseEnter={() => setIsHovered(true)} 
                onMouseLeave={() => setIsHovered(false)}
            >
                <div className={classes.tooltip}>{description}</div>
                <div className="expcard-img" style={{backgroundColor: theme.primary}}>
                    <img src={theme.type === 'light' ? expImgBlack : expImgWhite} alt="" />
                </div>
                <div className="experience-details">
                    <h6 style={{color: theme.primary}}>{startYear}-{endYear}</h6>
                    <h4 style={{color: theme.tertiary}}>{jobtitle}</h4>
                    <h5 style={{color: theme.tertiary80}}>{company}</h5>
                </div>
            </div>
        </Fade>   
    )
}

export default ExperienceCard