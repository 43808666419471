import one from '../assets/png/courseracerti/1.png'
import two from '../assets/png/courseracerti/2.png'
import three from '../assets/png/courseracerti/3.png'
import four from '../assets/png/courseracerti/4.png'
import five from '../assets/png/courseracerti/5.png'
import six from '../assets/png/courseracerti/6.png'
import seven from '../assets/png/courseracerti/7.png'
import eight from '../assets/png/courseracerti/8.png'
import nine from '../assets/png/courseracerti/9.png'
import ten from '../assets/png/courseracerti/10.png'
import eleven from '../assets/png/courseracerti/11.png'
import twelve from '../assets/png/courseracerti/12.png'
import thirteen from '../assets/png/courseracerti/13.png'
import fourteen from '../assets/png/courseracerti/14.png'
import fifteen from '../assets/png/courseracerti/15.png'
import sixteen from '../assets/png/courseracerti/16.png'
import seventeen from '../assets/png/courseracerti/17.png'
import eighteen from '../assets/png/courseracerti/18.png'
import nineteen from '../assets/png/courseracerti/19.png'
import twenty from '../assets/png/courseracerti/20.png'
import twentyone from '../assets/png/courseracerti/21.png'
import twentytwo from '../assets/png/courseracerti/22.png'
import twentythree from '../assets/png/courseracerti/23.png'
import twentyfour from '../assets/png/courseracerti/24.png'
import twentyfive from '../assets/png/courseracerti/25.png'
import twentysix from '../assets/png/courseracerti/26.png'


export const blogData = [
    {
        "id": 1,
        "title": "Custom and Distributed Training with TensorFlow",
        "description": "DeepLearning.AI",
        "date": "Apr-23",
        "image": one,
        "url": "https://www.coursera.org/account/accomplishments/certificate/UT2XSFNHB4DX"
    },
    {
        "id": 2,
        "title": "Advanced Computer Vision with TensorFlow",
        "description": "DeepLearning.AI",
        "date": "Apr-23",
        "image": two,
        "url": "https://www.coursera.org/account/accomplishments/certificate/KNGM3PH89PFK"
    },
    {
        "id": 3,
        "title": "Custom Models, Layers, and Loss Functions with TensorFlow",
        "description": "DeepLearning.AI",
        "date": "Apr-23",
        "image": three,
        "url": "https://www.coursera.org/account/accomplishments/certificate/4K8Y9KYPKQBS"
    },
    {
        "id": 4,
        "title": "Generative Deep Learning with TensorFlow",
        "description": "DeepLearning.AI",
        "date": "Apr-23",
        "image": four,
        "url": "https://www.coursera.org/account/accomplishments/certificate/M6VUAD6DUT49"
    },
    {
        "id": 5,
        "title": "TensorFlow: Advanced Techniques Specialization",
        "description": "DeepLearning.AI",
        "date": "Apr-23",
        "image": five,
        "url": "https://www.coursera.org/account/accomplishments/specialization/certificate/5UTEBPVZQJKQ"
    },
    {
        "id": 6,
        "title": "Foundations: Data, Data, Everywhere",
        "description": "Google",
        "date": "Apr-23",
        "image": six,
        "url": "https://www.coursera.org/account/accomplishments/certificate/ZS3APML66JEU"
    },
    {
        "id": 7,
        "title": "Ask Questions to Make Data-Driven Decisions",
        "description": "Google",
        "date": "Apr-23",
        "image": seven,
        "url": "https://www.coursera.org/account/accomplishments/certificate/QKPLCJBYQUW8"
    },
    {
        "id": 8,
        "title": "Fundamentals of Reinforcement Learning",
        "description": "University of Alberta",
        "date": "Apr-23",
        "image": eight,
        "url": "https://www.coursera.org/account/accomplishments/certificate/NQNFNVFMYM9S"
    },
    {
        "id": 9,
        "title": "Sample-based Learning Methods",
        "description": "University of Alberta",
        "date": "Apr-23",
        "image": nine,
        "url": "https://www.coursera.org/account/accomplishments/certificate/57LWA2MDBPZC"
    },
    {
        "id": 10,
        "title": "Prediction and Control with Function Approximation",
        "description": "University of Alberta",
        "date": "Apr-23",
        "image": ten,
        "url": "https://www.coursera.org/account/accomplishments/certificate/YXAXW4QEM8RP"
    },
    {
        "id": 11,
        "title": "A Complete Reinforcement Learning System (Capstone)",
        "description": "University of Alberta",
        "date": "Apr-23",
        "image": eleven,
        "url": "https://www.coursera.org/account/accomplishments/certificate/QCCC59DRDYHL"
    },
    {
        "id": 12,
        "title": "Reinforcement Learning Specialization",
        "description": "University of Alberta",
        "date": "Apr-23",
        "image": twelve,
        "url": "https://www.coursera.org/account/accomplishments/specialization/certificate/959DFU6B2FTT"
    },
    {
        "id": 13,
        "title": "Analyze Data to Answer Questions",
        "description": "Google",
        "date": "Apr-23",
        "image": thirteen,
        "url": "https://www.coursera.org/account/accomplishments/certificate/S3376NJQMWNY"
    },
    {
        "id": 14,
        "title": "Process Data from Dirty to Clean",
        "description": "Google",
        "date": "Apr-23",
        "image": fourteen,
        "url": "https://www.coursera.org/account/accomplishments/certificate/7PJFBRN843TR"
    },
    {
        "id": 15,
        "title": "Prepare Data for Exploration",
        "description": "Google",
        "date": "Apr-23",
        "image": fifteen,
        "url": "https://www.coursera.org/account/accomplishments/certificate/LY2P5EGWK7WR"
    },
    {
        "id": 16,
        "title": "Data Analysis with R Programming",
        "description": "Google",
        "date": "Apr-23",
        "image": sixteen,
        "url": "https://www.coursera.org/account/accomplishments/certificate/BRSN8WM6DP86"
    },
    {
        "id": 17,
        "title": "Google Data Analytics Capstone: Complete a Case Study",
        "description": "Google",
        "date": "Apr-23",
        "image": seventeen,
        "url": "https://www.coursera.org/account/accomplishments/certificate/ELVACPM3FD3T"
    },
    {
        "id": 18,
        "title": "Share Data Through the Art of Visualization",
        "description": "Google",
        "date": "Apr-23",
        "image": eighteen,
        "url": "https://www.coursera.org/account/accomplishments/certificate/TX5Q47A7A7ML"
    },
    {
        "id": 19,
        "title": "Google Data Analytics Specialization",
        "description": "Google",
        "date": "Apr-23",
        "image": nineteen,
        "url": "https://www.coursera.org/account/accomplishments/specialization/certificate/LDAW3V7P825M"
    },
    {
        "id": 20,
        "title": "Google Project Management: Specialization",
        "description": "Google",
        "date": "Sep-23",
        "image": twenty,
        "url": "https://www.coursera.org/account/accomplishments/specialization/certificate/7SXK2KUFQLLC"
    },
    {
        "id": 21,
        "title": "Project Execution: Running the Project",
        "description": "Coursera",
        "date": "Sep-23",
        "image": twentyone,
        "url": "https://www.coursera.org/account/accomplishments/certificate/V83EQGENX3DG"
    },
    {
        "id": 22,
        "title": "Foundations of Project Management",
        "description": "Coursera",
        "date": "Sep-23",
        "image": twentytwo,
        "url": "https://www.coursera.org/account/accomplishments/certificate/X5NKH3HZ6R2S"
    },
    {
        "id": 23,
        "title": "Project Planning: Putting It All Together",
        "description": "Coursera",
        "date": "Sep-23",
        "image": twentythree,
        "url": "https://www.coursera.org/account/accomplishments/certificate/EASKTQPB6N3S"
    },
    {
        "id": 24,
        "title": "Capstone: Applying Project Management in the Real World",
        "description": "Coursera",
        "date": "Sep-23",
        "image": twentyfour,
        "url": "https://www.coursera.org/account/accomplishments/certificate/6J3UX9BQB78H"
    },
    {
        "id": 25,
        "title": "Agile Project Management",
        "description": "Coursera",
        "date": "Sep-23",
        "image": twentyfive,
        "url": "https://www.coursera.org/account/accomplishments/certificate/93M9N65HL4DG"
    },
    {
        "id": 26,
        "title": "Project Initiation: Starting a Successful Project",
        "description": "Coursera",
        "date": "Sep-23",
        "image": twentysix,
        "url": "https://www.coursera.org/account/accomplishments/certificate/8UCPYB298V5E"
    }
    
]




// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/