import one from '../assets/png/research/1.png'
import two from '../assets/png/research/2.png'
import three from '../assets/png/research/3.png'
import four from '../assets/png/research/4.png'
import { NotInterested } from '@material-ui/icons'


export const projectsData = [
    {
        id: 1,
        projectName: 'AI&ML Powered Smart Item recommedation System',
        projectDesc: '',
        tags: [],
        code: 'https://drive.google.com/file/d/1SzOIeoiIFXJbT33OQWUgH0xy46Smz1F5/view?usp=sharing',
        demo: 'https://drive.google.com/file/d/19eWsarFGz5w-bqxPG9S7GcbhrR6Q0Ncc/view?usp=sharing',
        image: one
    },
    {
        id: 2,
        projectName: 'Deep Learning in Business Analytics, A Unique Approach',
        projectDesc: '',
        tags: [],
        code: 'https://drive.google.com/file/d/18gjXzNMNFJ6MY20yldMS7FWahLqMLb4a/view?usp=sharing',
        demo: 'https://drive.google.com/file/d/18gjXzNMNFJ6MY20yldMS7FWahLqMLb4a/view?usp=sharing',
        image: two
    },
    {
        id: 3,
        projectName: 'Gamma Business Intelligence',
        projectDesc: '',
        tags: [],
        code: 'https://drive.google.com/file/d/1KhHlwNYiHQvkJaDaCgr4WgPHIqmeAFZ9/view?usp=sharing',
        demo: 'https://drive.google.com/file/d/18gjXzNMNFJ6MY20yldMS7FWahLqMLb4a/view?usp=sharing',
        image: three
    },
    {
        id: 4,
        projectName: 'Smart Negotiator Chatbot',
        projectDesc: '',
        tags: [],
        code: 'https://drive.google.com/file/d/1cSxezAU67V4Rr4b6GOuRwy7ETvwxJhXQ/view?usp=sharing',
        demo: 'https://drive.google.com/file/d/1cSxezAU67V4Rr4b6GOuRwy7ETvwxJhXQ/view?usp=sharing',
        image: four
    },
    
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/