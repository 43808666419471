import one from '../assets/png/startups/one.png'
import two from '../assets/png/startups/two.png'
import three from '../assets/png/startups/three.png'
import four from '../assets/png/startups/four.png'

export const projectsData = [
    /*{
        id: 1,
        projectName: 'ZenShare',
        projectDesc: 'A blockchain driven Educational Content Sharing platform with limited-supply token economy for exponential value growth for all content users and creators.',
        tags: ['Solidity, Etherium','Nodejs, MongoDB, React', 'CSS, HTML, JS'],
        code: 'https://github.com/',
        demo: '',
        image: one
    },*/
    {
        id: 2,
        projectName: 'ZenRoom',
        projectDesc: 'Helps users take care of their mental health through mental workouts, an e-diary, a chatbot powered by the GPT API for online therapy, a community forum, Dashboard, Fitness tracker, Therapist finder,Disease Predictor',
        tags: ['Python, PyTorch, Tensorflow, Flask','Tailwind CSS,Html,JS','Openai API, Google API'],
        code: 'https://github.com/AI-ApeX-DeV/zenroom1',
        demo: 'https://zenroom.onrender.com',
        image: two
    },
    {
        id: 3,
        projectName: 'TCET OpenSource',
        projectDesc: 'FAANGM like ATS which uses Al toanalyse your resume and give yourecommendations on how to improve. It provides a standardized and unbiased approach to screening resumes.',
        tags: ['Python', 'NLTK, OpenCV, Tensorflow'],
        code: 'https://github.com/tcet-opensource/resume-screener',
        demo: 'https://opensource.tcetmumbai.in/',
        image: four
    },
    
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
