/* eslint-disable */
import a from '../assets/svg/testimonials/1.png'
import b from '../assets/svg/testimonials/2.png'

export const testimonialsData = [
    {
        id: 1,
        name: 'Tcet OpenSource',
        title: 'TCET',
        text: "Considering Syed's performance & + feedback from team leads, we are delighted to offer him opportunity to become a Maintainer and permanent member of the TCET Open Source",
        image: a
    },
    {
        id: 2,
        name: 'Jack Jay',
        title: 'CEO, Persist Ventures',
        text: "Syed's commitment to delivering high-quality results, work ethic. Syed played a  crucial role in the development of the Auto Form Filler Google Chrome extension.",
        image: b
    },
]

