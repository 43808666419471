import one from '../assets/png/talks/1.png'
import two from '../assets/png/talks/2.png'
import three from '../assets/png/talks/3.png'
import four from '../assets/png/talks/4.png'


import { NotInterested } from '@material-ui/icons'


export const projectsData = [
    {
        id: 1,
        projectName: 'Building End to End ML Project',
        projectDesc: 'Participants learned how to apply theoretical knowledge and practical tools to create impactful ML solutions along with deployment',
        tags: [],
        code: 'https://youtu.be/K8wcJs79FOs?si=YLg1nPbjhbL16OfG',
        demo: 'https://youtu.be/K8wcJs79FOs?si=YLg1nPbjhbL16OfG',
        image: one
    },
    {
        id: 2,
        projectName: 'Git & GitHub Workshop',
        projectDesc: ' participants were given an in-depth overview of Git. The session wrapped up with basic usage of GitHub by tasking participants to commit on First Contribution Board.',
        tags: [],
        code: 'https://youtu.be/WL5WIJI8PeU?si=GciMfr_zhTm8xHB_',
        demo: 'https://youtu.be/WL5WIJI8PeU?si=GciMfr_zhTm8xHB_',
        image: two
    },
    {
        id: 3,
        projectName: 'Resume Building Workshop',
        projectDesc: 'Students successfully learned about enhancing resume format, emphasizing open-source contributions, highlighting ICPC involvement, and implementing the Harvard keyword approach.',
        tags: [],
        code: 'https://youtu.be/_xWSTMBYYSg?si=TEdscqv6VII6MV7f',
        demo: 'https://youtu.be/_xWSTMBYYSg?si=TEdscqv6VII6MV7f',
        image: three
    },
    {
        id: 4,
        projectName: 'Relational Networks in machine learning',
        projectDesc: 'Participants learnt about how relational networks works along with hands on implementation and machematics in detail',
        tags: [],
        code: 'https://youtu.be/mQOQzea7I_g?si=YGbrS2hVM6qKd8EO',
        demo: 'https://youtu.be/mQOQzea7I_g?si=YGbrS2hVM6qKd8EO',
        image: four
    },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/