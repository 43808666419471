import one from '../assets/png/events/1.png'
import two from '../assets/png/events/2.png'
import three from '../assets/png/events/3.png'


export const blogData = [
    {
        "id": 2,
        "title": "Mumbai Tech Week",
        "description": "Had an opportunity to have a talk with Anumal Mittal CEO@Shaadi.com, CEO@Phone Pay, CEO@ Dream 11 and many more at JIO WORLD CONVENTION CENTRE",
        "date": "Mar-23",
        "image": two,
        "url": ""
    },
    {
        "id": 1,
        "title": "Solana Hacker House",
        "description": "Had a opportunity to learn some intricacies in web3 by Solana at JW MARRIOT MUMBAI",
        "date": "Mar-23",
        "image": one,
        "url": ""
    },
    {
        "id": 3,
        "title": "API DAY MUMBAI",
        "description": "Had a talk with Postman Expert and many other API experts",
        "date": "Mar-23",
        "image": three,
        "url": ""
    }

    
]




// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/