export const experienceData = [
    {
        id: 1,
        company: 'Persist Ventures',
        jobtitle: 'Backend Developer',
        location: 'California, USA (Remote-Internship)',
        Type: 'Remote',
        jobtype: 'Remote-Internship',
        startYear: 'June 2023',
        description: '• Improved the overall performance and working of the functions by 18% using data augmentation and ensemble learning.• Solved bugs and problems in the codebase.• Used Langchain, Transformer, Regex, NLTK .',
        endYear: 'August 2023'
    },
    {
        id: 2,
        company: 'TCET Opensource',
        jobtitle: 'AI/ML Developer',
        location: 'Mumbai, India (Internship)',
        Type: 'Hybrid',
        jobtype: 'Maintainer',
        startYear: 'May 2023',
        description: 'Used computer vision to calculate the blank area percentage ▪ Improved the accuracy of the model from 85% to 97% and alsoimproved the time complexity of functions ▪ Contributed to development & testing of the resume screening algorithm using TensorFlow, spacy and scikit-learn, NLP ▪ Implemented various API integrations to provide feedback to users along with their resume scores based on their skills.',
        endYear: 'July 2023'
    },
    {
        id: 2,
        company: 'TCET Opensource',
        jobtitle: 'Maintainer & Permanent Community Member',
        location: 'Mumbai, India',
        Type: 'Hybrid',
        jobtype: 'Maintainer',
        startYear: 'August 2023',
        description: 'Used computer vision to calculate the blank area percentage ▪ Improved the accuracy of the model from 85% to 97% and alsoimproved the time complexity of functions ▪ Contributed to development & testing of the resume screening algorithm using TensorFlow, spacy and scikit-learn, NLP ▪ Implemented various API integrations to provide feedback to users along with their resume scores based on their skills.',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'Infosys',
        jobtitle: 'Software Engineer',
        location: 'Mumbai, India (Internship)',
        Type: 'Hybrid',
        jobtype: 'Remote-Internship',
        startYear: 'April 2024',
        description: 'Ongoing',
        endYear: 'Present'
    },
]